<template>
  <CRow>
    <CCol col="12" xl="12">
      <CCard class="cards">
        <CCardBody>
          <div class="container flex-col col-md-12" id="print">
            <div class="inner-container flex-col">
              <div>
                <table class="mb-4 table table-striped">
                  <tbody class="heading1">
                    <tr>
                      <th>
                        <h3>{{ $t("message.paymentId") }} : {{ salesInvoice.id }}</h3>
                      </th>
                    </tr>
                    <tr>
                      <th><h5>{{ $t("message.customerName") }} :</h5></th>
                      <th>{{ customer.name }}</th>
                      <th><h5>{{ $t("message.customerPhone") }} :</h5></th>
                      <th>{{ customer.phone }}</th>
                    </tr>
                    <tr>
                      <th colspan="2">
                        <h4>{{ $t("message.invoicePrice") }} :</h4>
                      </th>
                      <th colspan="2">
                        <h4>{{ salesInvoice.price_paid | number }} SSP</h4>
                      </th>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </CCardBody>
        <CCardFooter>
          <CButton color="btn btn-primary" @click="goBack" class="m-auto">
            {{ $t("message.back") }}</CButton
          >
          <button
          type="button"
          @click="print"
          class="btn btn-warning"
          style=""
        >
        {{$t('message.print')}}
        </button>
        </CCardFooter>
      </CCard>
    </CCol>
  </CRow>
</template>

<script>
export default {
  name: 'ShowSub-Services',
  data () {
    return {
      salesInvoice: [],
      products: [],
      order: {},
      customer: {}
    }
  },
  created () {
    console.log(this.$route.params.id)
    this.$http
      .get(`${this.$hostUrl}sale-invoices/${this.$route.params.id}`)
      .then((response) => {
        this.salesInvoice = response.data.data.sale_invoice
        this.customer = this.salesInvoice.customer
      })
  },
  methods: {
    print () {
      this.$htmlToPaper('print')
    },
    goBack () {
      this.$router.push({ path: '/payments' })
    }
  }
}
</script>
<style scoped>
/* general styling */

* {
  box-sizing: border-box;
}

a {
  text-decoration: none;
}
.btn-warning {
  padding: 0.25rem 0.5rem;
  margin: 10px;
  color: #4f5d73;
  background-color: #f9b115;
  border-color: #f9b115;
}
button {
  color: #fff;
  letter-spacing: 0.8px;
  text-transform: uppercase;
  border-radius: 16px;
  padding: 5px;
  font-size: 1.2rem;
  background-color: #2a3647;
  width: 12rem;
  height: 3rem;
  border-width: 0px 2px 4px;
  border-style: solid;
  border-color: #2a3647;
  transition: 0.1s;
}

button:hover {
  background-color: #2a3647;
  border-color: #2a3647;
  cursor: pointer;
}

button:focus {
  outline: 0;
}

button:active {
  transition: 0.2s;
  border-width: 2px;
}

h2 {
  color: #2a3647;
  font-size: 1.5rem;
}

/* utility */

.flex-row {
  display: flex;
  flex-direction: row;
}

.flex-col {
  display: flex;
  flex-direction: column;
}

.center {
  justify-content: center;
  align-items: center;
}

/* structure */

.container {
  background-color: #fff;
  border: thick dashed #2a3647;
  padding: 2rem;
}

.title {
  text-align: center;
  padding: 1rem 0;
}

.container table {
  width: 100%;
  color: #2a3647;
  font-weight: 300;
}

.row-data td {
  /* width: 25%; */
  text-align: center;
  padding: 1rem;
  color: #2a3647 !important ;
}

tfoot {
  text-align: center;
  margin: 10px !important;
  padding: 10px !important;
}

tfoot tr * {
  padding: 1rem;
}

.row-data {
  border-bottom: 0.7px dashed #354728;
}

.row-heading th {
  color: #fff;
  /* width: 25%; */
  text-align: center !important;
  padding: 1rem;
  text-transform: uppercase;
  font-size: 0.9rem;
}
.row-heading1 th {
  color: #354728;
  background-color: #fff !important;
  /* width: 25%; */
  text-align: center !important;
  padding: 1rem;
  text-transform: uppercase;
  font-size: 1.5rem;
}
.heading1 th {
  color: #354728;
  background-color: #fff !important;
  /* width: 25%; */
  text-align: center !important;
  padding: 1rem;
  text-transform: uppercase;
  font-size: 1rem;
}
.item-total,
.total {
  font-weight: 400;
}

.btn-container {
  padding: 1rem 0 0;
}

/* footer */
</style>
